<template>
	<div class="flex justify-center items-center bg-gray-200 h-screen">
		<div class="bg-white shadow-lg rounded p-4">
			<h1 class="font-bold text-2xl text-center w-full">Octiq</h1>
			<div class="flex flex-col space-y-4 my-5">
                <div class="flex flex-col">
					<label>First Name</label>
					<input v-model="model.first_name" v-bind:class="{ invalid: validation.first_name }" class="border-2 border-blue-300 hover:border-blue-500 rounded py-2 px-2" type="text" placeholder="john" />
				</div>
                <div class="flex flex-col">
					<label>Last Name</label>
					<input v-model="model.last_name" v-bind:class="{ invalid: validation.last_name }" class="border-2 border-blue-300 hover:border-blue-500 rounded py-2 px-2" type="text" name="last_name" placeholder="Doe" />
				</div>
				<div class="flex flex-col">
					<label>Email</label>
					<input v-model="model.email" v-bind:class="{ invalid: validation.email }" class="border-2 border-blue-300 hover:border-blue-500 rounded py-2 px-2" type="text" placeholder="john@example.org" />
				</div>
                <div class="flex flex-col">
					<label>Mobile</label>
					<input v-model="model.mobile" v-bind:class="{ invalid: validation.mobile }" class="border-2 border-blue-300 hover:border-blue-500 rounded py-2 px-2" type="text" placeholder="+94777123456" />
				</div>
				<div class="flex flex-col">
					<label>Password</label>
					<input v-model="model.password" v-bind:class="{ invalid: validation.password }" class="border-2 border-blue-300 hover:border-blue-500 rounded py-2 px-2" type="password" placeholder="Password" />
				</div>
                <div class="flex flex-col">
					<label>Store Name</label>
					<input v-model="model.company" v-bind:class="{ invalid: validation.company }" class="border-2 border-blue-300 hover:border-blue-500 rounded py-2 px-2" type="text" placeholder="Abc Inc" />
				</div>
			</div>
			<button v-on:click="register" class="w-80 bg-blue-500 rounded p-2 text-white">Register</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "Register",

    data: () => ({
        model: {
            first_name: null,
            last_name: null,
            email: null,
            mobile: null,
            password: null,
            company: null
        },
        validation: {
            first_name: null,
            last_name: null,
            email: null,
            mobile: null,
            password: null,
            company: null
        }
    }),

	methods: {

		register() {

            /**
             * We need to reset all the error fields before request
             * registration again.
             */
            this.validation.first_name = false
            this.validation.last_name = false
            this.validation.email = false
            this.validation.mobile = false
            this.validation.password = false
            this.validation.company = false

			this.$store.dispatch("user/register", {
                first_name: this.model.first_name,
                last_name: this.model.last_name,
                email: this.model.email,
                mobile: this.model.mobile,
                password: this.model.password,
                company: this.model.company
			}).then(() => {
                this.$router.push("/home")
            }).catch(Response => {
                Response.error.fields.forEach(field => {
                    this.validation[field.field] = true
                    console.log(field.field)
                })
            })

		}

	}
}
</script>

<style scoped>
label {
	font-family: "Open Sans";
	font-size: 12px;
	font-weight: 600;
	color: #848484;
}

input.invalid {
    border-color: red !important;
}

input:focus-visible {
	outline: none !important;
}
</style>
